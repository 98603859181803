@media screen and (max-width: map-get($breakpoints, 'medium')) {
  .mosaic {
    &--missions {
      .mosaic {
        &__element-link {
          .image {
            &__wrapper {
              height: 27vw;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: map-get($breakpoints, 'small')) {
  .mosaic {
    &--grid {
      grid-template-columns: repeat(auto-fill, minmax(calc(50% - 10px), 1fr));

      .mosaic {
        &__element {
          grid-column: unset !important;
          grid-row: unset !important;
        }
      }
    }

    &--masonry {
      > .mosaic {
        &__element {
          width: calc(100% - .5rem);
        }
      }
    }

    &--missions {
      .mosaic {
        &__element-link {
          .image {
            &__wrapper {
              height: 50vw;
            }
          }
        }
      }
    }
  }
}