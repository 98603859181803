.logo {
  display: flex;
  justify-content: center;
  position: relative;

  &__picto,
  &__text {
    align-items: center;
    display: flex;
  }

  &__picto {
    justify-content: center;
    padding-left: 0;
    transition: padding-left .25s ease .2s, width .25s ease .2s;
    width: 4rem;

    .image {
      height: $huge-spacing;
      transition: height .25s;
    }
  }

  &__text {
    padding: 0 $small-spacing 0 $default-spacing;
    transition: padding .25s ease .2s;

    .image {
      height: $default-spacing;
      transition: height .25s;
    }
  }
}

@import 'media-queries';
