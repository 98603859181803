@media screen and (min-width: map-get($breakpoints, 'medium')) {
  .commercial {
    &__col-1 {
      border-right: .15rem dashed $gray-e;

      .commercial {
        &__picto {
          margin-left: 6vw;
        }
      }
    }
  }
}

@media screen and (max-width: map-get($breakpoints, 'medium')) {
  .commercial {
    &__col-1,
    &__col-2 {
      margin-left: 0;

      .text {
        text-align: center;
      }
    }

    &__col-1 {
      .commercial {
        &__text {
          flex-wrap: wrap;
          margin-right: 0;
          position: relative;
          top: -1rem;
        }
      }

      .text {
        padding-right: 0;
      }
    }

    &__col-2 {
      .commercial {
        &__text {
          margin: 0 5rem;
        }
      }

      .text {
        font-weight: normal;
      }
    }

    &__picto {
      margin-left: 10vw;
    }
  }
}

@media screen and (max-width: map-get($breakpoints, 'small')) {
  .commercial {
    &__col-2 {
      .commercial {
        &__text {
          margin: 0;
        }
      }
    }
  }
}