@media screen and (max-width: map-get($breakpoints, 'medium')) {
  .ev-text {
    &--m {
      &-left {
        justify-content: left !important;
        text-align: left !important;
      }

      &-right {
        justify-content: right !important;
        text-align: right !important;
      }

      &-center {
        justify-content: center !important;
        text-align: center !important;
      }
    }

    &--responsive {
      font-size: 1.1rem !important;
      line-height: 1.5rem;

      &-big {
        font-size: 2.5vw !important;
        line-height: 3.5vw;
      }
    }
  }
}

@media screen and (max-width: map-get($breakpoints, 'small')) {
  .ev-text {
    &--s {
      &-left {
        justify-content: left !important;
        text-align: left !important;
      }

      &-right {
        justify-content: right !important;
        text-align: right !important;
      }

      &-center {
        justify-content: center !important;
        text-align: center !important;
      }
    }

    &--responsive {
      font-size: $default-spacing !important;
      line-height: 1.4rem;

      &-big {
        font-size: 1.2rem !important;
        line-height: 1.6rem;
      }
    }
  }
}