.commercial {
  display: flex;

  &--home-baseline {
    flex-direction: column;
    margin-right: 12%;
  }

  &__col-1,
  &__col-2,
  &__text {
    display: flex;
  }

  &__col-1,
  &__col-2 {
    flex-direction: column;
    justify-content: space-between;
    margin-left: 3.5vw;

    .commercial {
      &__text {
        flex-direction: column;
      }
    }
  }

  &__col-1 {
    .commercial {
      &__text {
        display: grid;
      }
    }

    .text {
      padding-right: 4vw;
      text-align: right;
      white-space: nowrap;
    }
  }
}

@import 'media-queries';
