p {
  &.text {
    margin-bottom: $default-spacing;
  }
}

.text {
  font-size: 1.1rem;

  &--chapo {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: $default-spacing;
  }

  &--huge {
    font-size: $big-spacing !important;
  }

  &--large {
    font-size: $medium-spacing !important;
  }

  &--big {
    font-size: 1.25rem !important;
  }

  &--medium {
    font-size: 1.1rem !important;
  }

  &--default,
  &--normal {
    font-size: $default-spacing !important;
  }

  &--small {
    font-size: .9rem !important;
  }

  &--tiny {
    font-size: .8rem !important;
  }

  &__link {
    color: $color-alt;

    &:hover,
    &:active,
    &:focus {
      color: $color-warning;
    }
  }

  @each $name, $color in $colors {
    &--#{$name} {
      color: $color;
    }
  }
}

@import 'media-queries';
