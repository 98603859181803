@media screen and (min-width: map-get($breakpoints, 'medium')) {
  .ev {
    &-small-only {
      display: none !important;
    }

    &-medium-only {
      display: none !important;
    }

    &-large-only {
      display: flex !important;
    }

    &-hide {
      &--small-only {
        display: flex !important;
      }

      &--medium-only {
        display: flex !important;
      }

      &--large {
        display: none !important;
      }

      &--large-only {
        display: none !important;
      }
    }
  }
}

@media screen and (max-width: map-get($breakpoints, 'medium')) {
  .ev {
    &-small-only {
      display: none !important;
    }

    &-medium-only {
      display: flex !important;
    }

    &-large-only {
      display: none !important;
    }

    &-hide {
      &--small-only {
        display: flex !important;
      }

      &--medium {
        display: none !important;
      }

      &--medium-only {
        display: none !important;
      }

      &--large-only {
        display: flex !important;
      }
    }
  }
}

@media screen and (max-width: map-get($breakpoints, 'small')) {
  .ev {
    &-small-only {
      display: flex !important;
    }

    &-medium-only {
      display: none !important;
    }

    &-large-only {
      display: none !important;
    }

    &-hide {
      &--small,
      &--medium {
        display: none !important;
      }

      &--small-only {
        display: none !important;
      }

      &--medium-only {
        display: flex !important;
      }

      &--large-only {
        display: flex !important;
      }
    }
  }
}
