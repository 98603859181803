@media screen and (max-width: map-get($breakpoints, 'medium')) {
  .page {
    &__wrapper {
      margin-left: -2rem;
    }
  }
}

@media screen and (max-width: map-get($breakpoints, 'small')) {
  .page {
    &__wrapper {
      margin-left: -4rem;
      width: calc(100% + 4rem);
    }
  }
}