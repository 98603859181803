@mixin test($properties-size-unit) {
  // Variables //

  $properties-size-values: (
    'tiny': #{.25}#{$properties-size-unit},
    'small': #{.5}#{$properties-size-unit},
    'default': #{1}#{$properties-size-unit},
    'medium': #{1.5}#{$properties-size-unit},
    'big': #{2}#{$properties-size-unit},
    'huge': #{3}#{$properties-size-unit},
    'half': 50%,
    'tier': calc(100% / 3),
    'tier-2': calc(100% / 1.5),
    'quarter': 25%,
    'quarter-3': 75%,
    'sixth': calc(100% / 6),
    'sixth-5': calc(500% / 6),
    'full': 100%,
    'viewport-height': 100vh,
    'viewport-width': 100vw,
    'unset': unset,
    '0': 0,
    'none': 0,
    'auto': auto,
  ) !default;

  // flex-basis //

  @each $name, $value in $properties-size-values {
    .ev-basis--#{$name} {
      flex-basis: #{$value} !important;
    }
  }

  @for $i from $properties-min-size-value through $properties-max-size-value {
    .ev-basis--#{$i} {
      flex-basis: #{$i}#{$properties-size-unit} !important;
    }
  }

  // margin //

  @each $name, $value in $properties-size-values {
    .ev-margin {
      &--#{$name} {
        margin: #{$value} !important;
      }

      &--t {
        &-#{$name} {
          margin-top: #{$value} !important;
        }
      }

      &--r {
        &-#{$name} {
          margin-right: #{$value} !important;
        }
      }

      &--b {
        &-#{$name} {
          margin-bottom: #{$value} !important;
        }
      }

      &--l {
        &-#{$name} {
          margin-left: #{$value} !important;
        }
      }

      &--tb {
        &-#{$name} {
          margin-bottom: #{$value} !important;
          margin-top: #{$value} !important;
        }
      }

      &--lr {
        &-#{$name} {
          margin-left: #{$value} !important;
          margin-right: #{$value} !important;
        }
      }
    }
  }

  @for $i from $properties-min-size-value through $properties-max-size-value {
    .ev-margin {
      &--#{$i} {
        margin: #{$i}#{$properties-size-unit} !important;
      }

      &--t {
        &-#{$i} {
          margin-top: #{$i}#{$properties-size-unit} !important;
        }
      }

      &--r {
        &-#{$i} {
          margin-right: #{$i}#{$properties-size-unit} !important;
        }
      }

      &--b {
        &-#{$i} {
          margin-bottom: #{$i}#{$properties-size-unit} !important;
        }
      }

      &--l {
        &-#{$i} {
          margin-left: #{$i}#{$properties-size-unit} !important;
        }
      }

      &--tb {
        &-#{$i} {
          margin-bottom: #{$i}#{$properties-size-unit} !important;
          margin-top: #{$i}#{$properties-size-unit} !important;
        }
      }

      &--lr {
        &-#{$i} {
          margin-left: #{$i}#{$properties-size-unit} !important;
          margin-right: #{$i}#{$properties-size-unit} !important;
        }
      }
    }
  }

  // padding //

  @each $name, $value in $properties-size-values {
    .ev-padding {
      &--#{$name} {
        padding: #{$value} !important;
      }

      &--t {
        &-#{$name} {
          padding-top: #{$value} !important;
        }
      }

      &--r {
        &-#{$name} {
          padding-right: #{$value} !important;
        }
      }

      &--b {
        &-#{$name} {
          padding-bottom: #{$value} !important;
        }
      }

      &--l {
        &-#{$name} {
          padding-left: #{$value} !important;
        }
      }

      &--tb {
        &-#{$name} {
          padding-bottom: #{$value} !important;
          padding-top: #{$value} !important;
        }
      }

      &--lr {
        &-#{$name} {
          padding-left: #{$value} !important;
          padding-right: #{$value} !important;
        }
      }
    }
  }

  @for $i from $properties-min-size-value through $properties-max-size-value {
    .ev-padding {
      &--#{$i} {
        padding: #{$i}#{$properties-size-unit} !important;
      }

      &--t {
        &-#{$i} {
          padding-top: #{$i}#{$properties-size-unit} !important;
        }
      }

      &--r {
        &-#{$i} {
          padding-right: #{$i}#{$properties-size-unit} !important;
        }
      }

      &--b {
        &-#{$i} {
          padding-bottom: #{$i}#{$properties-size-unit} !important;
        }
      }

      &--l {
        &-#{$i} {
          padding-left: #{$i}#{$properties-size-unit} !important;
        }
      }

      &--tb {
        &-#{$i} {
          padding-bottom: #{$i}#{$properties-size-unit} !important;
          padding-top: #{$i}#{$properties-size-unit} !important;
        }
      }

      &--lr {
        &-#{$i} {
          padding-left: #{$i}#{$properties-size-unit} !important;
          padding-right: #{$i}#{$properties-size-unit} !important;
        }
      }
    }
  }

  // width //

  @each $name, $value in $properties-size-values {
    .ev-width--#{$name} {
      width: #{$value} !important;
    }
  }

  @for $i from $properties-min-size-value through $properties-max-size-value {
    .ev-width--#{$i} {
      width: #{$i}#{$properties-size-unit} !important;
    }
  }

  // min-width //

  @each $name, $value in $properties-size-values {
    .ev-min-w--#{$name} {
      min-width: #{$value} !important;
    }
  }

  @for $i from $properties-min-size-value through $properties-max-size-value {
    .ev-min-w--#{$i} {
      min-width: #{$i}#{$properties-size-unit} !important;
    }
  }

  // max-width //

  @each $name, $value in $properties-size-values {
    .ev-max-w--#{$name} {
      max-width: #{$value} !important;
    }
  }

  @for $i from $properties-min-size-value through $properties-max-size-value {
    .ev-max-w--#{$i} {
      max-width: #{$i}#{$properties-size-unit} !important;
    }
  }

  // height //

  @each $name, $value in $properties-size-values {
    .ev-height--#{$name} {
      height: #{$value} !important;
    }
  }

  @for $i from $properties-min-size-value through $properties-max-size-value {
    .ev-height--#{$i} {
      height: #{$i}#{$properties-size-unit} !important;
    }
  }

  // min-height //

  @each $name, $value in $properties-size-values {
    .ev-min-h--#{$name} {
      min-height: #{$value} !important;
    }
  }

  @for $i from $properties-min-size-value through $properties-max-size-value {
    .ev-min-h--#{$i} {
      min-height: #{$i}#{$properties-size-unit} !important;
    }
  }

  // max-height //

  @each $name, $value in $properties-size-values {
    .ev-max-h--#{$name} {
      max-height: #{$value} !important;
    }
  }

  @for $i from $properties-min-size-value through $properties-max-size-value {
    .ev-max-h--#{$i} {
      max-height: #{$i}#{$properties-size-unit} !important;
    }
  }
}