@media screen and (max-width: map-get($breakpoints, 'medium')) {
  .logo {
    &__text {
      padding: 0 $small-spacing 0 0;
      transition: padding .25s ease 0s;
    }
  }
}

@media screen and (max-width: map-get($breakpoints, 'small')) {
  .logo {
    min-height: $huge-spacing;
  }
}


