@mixin image($image-size) {
  max-height: $image-size;
  max-width: $image-size;
  min-height: $image-size;

  .image {
    max-height: $image-size;
    max-width: $image-size;
  }
}
