body {
  font-size: $default-spacing;
}

a {
  color: $color-main;
  transition: color .15s;
}

button {
  font-family: 'myriad pro', serif;
}
