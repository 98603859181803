.ev {
  @each $name, $color in $colors {
    // Couleur du texte

    &-text {
      &--#{$name} {
        color: $color !important;

        &-hover {
          &:hover,
          &:focus,
          &:active {
            color: $color !important;
          }
        }
      }
    }

    // Couleur de l'arrière plan

    &-bg {
      &--#{$name} {
        background-color: $color !important;

        &-hover {
          &:hover,
          &:focus,
          &:active {
            background-color: $color !important;
          }
        }
      }
    }

    // Couleur des bordures //

    &-border {
      &--#{$name} {
        border-color: $color !important;

        &-hover {
          &:hover,
          &:focus,
          &:active {
            border-color: $color !important;
          }
        }
      }
    }

    // Couleur du "before" //

    &-before {
      &--#{$name} {
        &::before {
          color: $color !important;
        }

        &-hover {
          &:hover,
          &:focus,
          &:active {
            &::before {
              color: $color !important;
            }
          }
        }
      }

      &-bg {
        &--#{$name} {
          &::before {
            background: $color !important;
          }
        }
      }
    }

    // Couleur du "after" //

    &-after {
      &--#{$name} {
        &::after {
          color: $color !important;
        }

        &-hover {
          &:hover,
          &:focus,
          &:active {
            &::after {
              color: $color !important;
            }
          }
        }
      }

      &-bg {
        &--#{$name} {
          &::after {
            background: $color !important;
          }
        }
      }
    }
  }

  // Dégradé de l'arrière plan

  @each $name, $color in $gradients {
    &-gradient {
      &--#{$name} {
        background-image: $color !important;
      }
    }
  }

  // Ne pas afficher les bordures, ombres et pseudo-éléments //

  &-border {
    &--none {
      border: 0 !important;
    }
  }

  &-before {
    &--none {
      &::before {
        display: none !important;
      }
    }
  }

  &-after {
    &--none {
      &::after {
        display: none !important;
      }
    }
  }

  &-shadow {
    &--none {
      box-shadow: none !important;
    }
  }
}
