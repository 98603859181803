@media screen and (max-width: map-get($breakpoints, 'medium')) {
  .title {
    &:not(.title--dot):not(.title--dot-line):not(.title--small) {
      font-size: 2vw;
      line-height: 3vw;
    }
  }
}

@media screen and (max-width: map-get($breakpoints, 'small')) {
  .title {
    &:not(.title--dot):not(.title--dot-line):not(.title--small) {
      font-size: 1.2rem;
      line-height: 1.6rem;
    }

    &--big {
      .icon {
        margin: $small-spacing;
      }
    }

    &--dot-line {
      font-size: 1.1rem;
      line-height: 1.5rem;
    }

    .icon {
      &::before {
        font-size: 1.1rem;
      }
    }
  }
}
