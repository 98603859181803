@media screen and (max-width: map-get($breakpoints, 'medium')) {
  .text {
    &--huge {
      font-size: $medium-spacing !important;
    }

    &--large {
      font-size: 1.25rem !important;
    }

    &--big {
      font-size: 1.1rem !important;
    }

    &--medium {
      font-size: $default-spacing !important;
    }

    &--default {
      font-size: .9rem !important;
    }

    &--small {
      font-size: .8rem !important;
    }

    &--tiny {
      font-size: .7rem !important;
    }
  }
}

@media screen and (max-width: map-get($breakpoints, 'small')) {
  .text {
    &--huge {
      font-size: 1.2rem !important;
    }

    &--large {
      font-size: 1.1rem !important;
    }

    &--big {
      font-size: $default-spacing !important;
    }

    &--medium {
      font-size: .9rem !important;
    }

    &--default {
      font-size: .8rem !important;
    }

    &--small {
      font-size: .7rem !important;
    }
  }
}
