@mixin titleBig() {
  font-family: 'Glacial Indifference', century ghotic, sans-serif;
  font-size: 1.6vw;
  line-height: 2.5vw;
  text-align: center;
  width: 100%;
}

.title {
  align-items: center;
  display: inline-flex;
  flex-wrap: wrap;
  font-size: $medium-spacing;
  line-height: 1.8rem;
  margin-bottom: $default-spacing;
  position: relative;
  width: 100%;

  &:not(.title--dot):not(.title--dot-line):not(.title--small) {
    text-transform: uppercase;
  }

  .icon {
    color: $color-alt;
    margin-right: $small-spacing;
  }

  &__wrapper {
    display: flex;

    .title {
      margin-right: auto;
      width: auto;
    }
  }

  &--big,
  &--page {
    @include titleBig;
    text-transform: uppercase;

    .icon {
      margin-right: $default-spacing;
    }
  }

  &--page {
    .title {
      &__text {
        margin-right: auto;
        text-align: left;
        width: auto;
      }
    }
  }

  &--center {
    &::after {
      margin-left: calc(50% - .75rem);
      margin-right: calc(50% - .75rem);
    }

    .icon {
      margin-left: auto;
    }
  }

  &--medium {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }

  &--small {
    font-size: 1.1rem;
    font-weight: bold;
    line-height: $medium-spacing;
  }

  &--dot,
  &--dot-line {
    flex-wrap: nowrap;
    font-size: 1.4rem;
    line-height: 1.8rem;

    &::before {
      color: $blue;
      content: '■';
      margin-right: $small-spacing;
      padding-bottom: $tiny-spacing;
      position: relative;
    }
  }

  &--dot-line {
    &::after {
      border-bottom: .1rem solid $gray-e;
      content: '';
      display: block;
      flex-grow: 2;
      margin-left: $default-spacing;
    }
  }
}

@import 'media-queries';
