// Variables //
$properties-min-size-value: 4 !default;
$properties-max-size-value: 30 !default;
$properties-min-order-value: 0 !default;
$properties-max-order-value: 15 !default;

$border-size-values: (
  '0': 0,
  '1': 1px,
  '2': 2px,
  '3': 3px,
  '5': 5px,
) !default;

@import 'mixins';

// flex-grow //

@for $i from 0 through 7 {
  .ev-grow--#{$i} {
    flex-grow: #{$i} !important;
  }
}

// flex-shrink //

@for $i from -1 through 7 {
  .ev-shrink--#{$i} {
    flex-shrink: #{$i} !important;
  }
}

// flex-direction //

.ev-direction {
  &--row {
    flex-direction: row !important;
  }

  &--column {
    flex-direction: column !important;
  }

  &--row-reverse {
    flex-direction: row-reverse !important;
  }

  &--column-reverse {
    flex-direction: column-reverse !important;
  }
}

// justify-content //

.ev-justify {
  &--center {
    justify-content: center !important;
  }

  &--start {
    justify-content: flex-start !important;
  }

  &--end {
    justify-content: flex-end !important;
  }

  &--between {
    justify-content: space-between !important;
  }

  &--around {
    justify-content: space-around !important;
  }

  &--evenly {
    justify-content: space-evenly !important;
  }
}

// flex-align //

.ev-align {
  &--center {
    align-items: center !important;
  }

  &--start {
    align-items: flex-start !important;
  }

  &--end {
    align-items: flex-end !important;
  }

  &--baseline {
    align-items: baseline !important;
  }

  &--stretch {
    align-items: stretch !important;
  }
}


// align-self //

.ev-self {
  &--center {
    align-self: center !important;
  }

  &--start {
    align-self: flex-start !important;
  }

  &--end {
    align-self: flex-end !important;
  }

  &--baseline {
    align-self: baseline !important;
  }

  &--stretch {
    align-self: stretch !important;
  }
}

// order //

@for $i from $properties-min-order-value through $properties-max-order-value {
  .ev-order {
    &--#{$i} {
      order: #{$i} !important;
    }
  }
}

// flex-wrap //

.ev-wrap {
  flex-wrap: wrap !important;
}

.ev-nowrap {
  flex-wrap: nowrap !important;
}

// border //

@each $name, $value in $border-size-values {
  .ev-border--b-#{$name} {
    border-bottom: 1px solid $gray-c;
  }

  .ev-border--l-#{$name} {
    border-left: 1px solid $gray-c;
  }

  .ev-border--r-#{$name} {
    border-right: 1px solid $gray-c;
  }

  .ev-border--t-#{$name} {
    border-top: 1px solid $gray-c;
  }

  .ev-border--#{$name} {
    border-bottom: 1px solid $gray-c;
    border-left: 1px solid $gray-c;
    border-right: 1px solid $gray-c;
    border-top: 1px solid $gray-c;
  }

  .ev-border--tb-#{$name} {
    border-bottom: 1px solid $gray-c;
    border-top: 1px solid $gray-c;
  }

  .ev-border--lr-#{$name} {
    border-left: 1px solid $gray-c;
    border-right: 1px solid $gray-c;
  }
}

@import 'media-queries';
