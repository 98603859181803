.list {
  display: flex;
  flex-direction: column;
  position: relative;

  &__label {
    color: $gray-c;
    margin-right: $small-spacing;
  }

  &__element {
    color: $gray-40;
    display: flex;
    font-size: 1.1rem;

    &:not(:last-of-type) {
      margin-bottom: 0;
      padding-bottom: $small-spacing;
    }
  }

  &__link {
    color: $color-alt;

    &:hover,
    &:active {
      color: $color-main;
    }
  }

  &--dot {
    .list {
      &__element {
        &::before {
          @include pseudo-element;
          background: $gray-c;
          margin-right: .75rem;
          max-height: $small-spacing;
          max-width: $small-spacing;
          min-height: $small-spacing;
          min-width: $small-spacing;
          position: relative;
          top: $small-spacing;
        }
      }
    }

    &.list {
      &--colored {
        .list {
          &__element {
            &::before {
              background: $color-alt;
            }
          }
        }
      }
    }
  }

  &--link {
    .list {
      &__element {
        &:not(:last-of-type) {
          padding-bottom: $default-spacing;
        }
      }
    }

    &.list {
      &--colored {
        .list {
          &__element {
            &::before {
              border-left-color: $color-alt;
            }
          }
        }
      }
    }
  }

  &--tags {
    .list {
      &__link {
        background-color: $color-alt;
        color: $white;
        font-family: $font-family-default;
        font-size: $default-spacing;
        height: $big-spacing;
        padding: $tiny-spacing $small-spacing;

        &:hover,
        &:active {
          background-color: $gray-40;
        }
      }

      &__element {
        margin-top: $small-spacing;
        padding-bottom: 0;

        &:not(:last-of-type) {
          margin-right: $small-spacing;
        }

        &.is-checked {
          .list {
            &__link {
              background-color: $gray-40 !important;
              color: $white;
            }
          }
        }
      }
    }

    &.list {
      &--gray {
        .list {
          &__link {
            background-color: $gray-e;
            color: $gray-33;

            &:hover,
            &:active {
              color: $white;
            }

            &:hover,
            &:active {
              background-color: $color-alt;
            }
          }

          &__element {
            &.is-checked {
              .list {
                &__link {
                  background-color: $gray-40 !important;
                }
              }
            }
          }
        }
      }
    }
  }

  &--pipe {
    .list {
      &__element {
        &:not(:last-of-type) {
          &::after {
            color: $gray-40;
            content: '|';
            font-weight: bold;
            margin: 0 $small-spacing;
          }
        }
      }
    }
  }

  &--center {
    .list {
      &__element {
        justify-content: center;
        text-align: center;
      }
    }
  }

  &--small {
    .list {
      &__label,
      &__value,
      &__element {
        font-size: .9rem;
      }

      &__element {
        line-height: 1.4rem;
        padding-bottom: 0 !important;
      }
    }
  }

  &--medium {
    .list {
      &__label,
      &__value {
        font-size: 1.1rem;

        &--complement {
          font-size: .9rem;
        }
      }

      &__element {
        font-size: 1.1rem;
        padding-bottom: $default-spacing;
      }
    }
  }

  &--inline {
    flex-direction: row;
    flex-wrap: wrap;
  }

  &--separator {
    width: 100%;

    .list {
      &__element {
        border-top: 1px solid $gray-fa;
        padding: $small-spacing;
      }
    }
  }
}

@import 'media-queries';
