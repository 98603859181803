// Variables //

$font-family-default: 'Helvetica', 'Arial' !default;
$font-size-default: 100% !default;

$font-size-tiny: .7rem !default;
$font-size-small: .85rem !default;
$font-size-default: 1rem !default;
$font-size-medium: 1.2rem !default;
$font-size-large: 1.5rem !default;
$font-size-big: 2rem !default;
$font-size-huge: 3rem !default;
$font-size-4: 4rem !default;
$font-size-6: 6rem !default;
$font-size-8: 8rem !default;

$font-weight-normal: normal;
$font-weight-bold: bold;

// Tableau des tailles de police //

$font-sizes: (
  'tiny': $font-size-tiny,
  'small': $font-size-small,
  'default': $font-size-default,
  'medium': $font-size-medium,
  'large': $font-size-large,
  'big': $font-size-big,
  'huge': $font-size-huge,
  '4': $font-size-4,
  '6': $font-size-6,
  '8': $font-size-8,
  'responsive': 1.2vw,
  'responsive-big': 1.9vw,
) !default;

body {
  font-family: $font-family-default;
  font-size: $font-size-default;
}
