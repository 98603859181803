.footer {
  display: flex;
  justify-content: center;
  margin-top: $big-spacing;
  padding: $default-spacing;
  text-align: center;
  z-index: 4;

  &__copyright {
    color: $gray-c;
    font-size: .8rem;
  }
}

@import 'media-queries';
