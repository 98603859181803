.breadcrumbs {
  align-items: center;
  display: flex;

  &__link {
    color: $color-alt;

    &:last-of-type {
      .breadcrumbs {
        &__link {
          color: $color-alt;
        }
      }
    }

    &::before {
      color: $gray-c;
      content: '/';
      font-weight: bold;
      margin: 0 .5rem;
    }

    &:first-of-type {
      &::before {
        content: '//';
      }
    }

    &:hover,
    &:focus {
      color: $gray-33;
    }
  }
}

@import 'media-queries';
