// Taille des gouttières du "Layout 12 colonnes" //

@import 'mixins';

// Variables "element"

$ev-element-margin: $default-spacing !default;

.ev-element {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 100%;
    width: 100%;

    &--gutter {
      left: #{$ev-element-margin * -1};
      max-width: calc(100% + #{$ev-element-margin * 2});
      min-width: calc(100% + #{$ev-element-margin * 2});
      position: relative;

      > .ev-element {
        margin: 0 #{$ev-element-margin};

        &--half {
          max-width: calc(50% - #{$ev-element-margin * 2}) !important;
          min-width: calc(50% - #{$ev-element-margin * 2}) !important;
        }

        &--tier {
          max-width: calc(100% / 3 - #{$ev-element-margin * 2}) !important;
          min-width: calc(100% / 3 - #{$ev-element-margin * 2}) !important;
        }

        &--tier-2 {
          max-width: calc(100% / 1.5 - #{$ev-element-margin * 2}) !important;
          min-width: calc(100% / 1.5 - #{$ev-element-margin * 2}) !important;
        }

        &--quarter {
          max-width: calc(25% - #{$ev-element-margin * 2}) !important;
          min-width: calc(25% - #{$ev-element-margin * 2}) !important;
        }

        &--quarter-3 {
          max-width: calc(75% - #{$ev-element-margin * 2}) !important;
          min-width: calc(75% - #{$ev-element-margin * 2}) !important;
        }

        &--sixth {
          max-width: calc(100% / 6 - #{$ev-element-margin * 2}) !important;
          min-width: calc(100% / 6 - #{$ev-element-margin * 2}) !important;
        }

        &--sixth-5 {
          max-width: calc(500% / 6 - #{$ev-element-margin * 2}) !important;
          min-width: calc(500% / 6 - #{$ev-element-margin * 2}) !important;
        }

        .ev-element {
          margin-bottom: 0 !important;
        }
      }

      ~ .ev-element {
        &__wrapper {
          &--gutter {
            margin-top: $default-spacing;

            &:last-of-type {
              margin-bottom: $default-spacing;
            }
          }
        }
      }
    }

    > .ev-element {
      flex-basis: 0;
      flex-grow: 1;
      flex-shrink: 1;
    }

    &--same-height {
      > .ev-element {
        display: flex;

        > .ev-section {
          flex-grow: 2;
        }
      }
    }
  }

  &--half {
    max-width: 50% !important;
    min-width: 50% !important;
  }

  &--tier {
    max-width: calc(100% / 3) !important;
    min-width: calc(100% / 3) !important;
  }

  &--tier-2 {
    max-width: calc(100% / 1.5) !important;
    min-width: calc(100% / 1.5) !important;
  }

  &--quarter {
    max-width: 25% !important;
    min-width: 25% !important;
  }

  &--quarter-3 {
    max-width: 75% !important;
    min-width: 75% !important;
  }

  &--sixth {
    max-width: calc(100% / 6) !important;
    min-width: calc(100% / 6) !important;
  }

  &--sixth-5 {
    max-width: calc(500% / 6) !important;
    min-width: calc(500% / 6) !important;
  }
}

@import 'media-queries';
