@media screen and (max-width: map-get($breakpoints, 'medium')) {
  .content {
    margin: 0 0 0 4rem;
    overflow: initial;
    padding: 0 $big-spacing;
    transition: margin-right 0.25s ease 0s, padding 0.25s ease 0s;
  }
}

@media screen and (max-width: map-get($breakpoints, 'small')) {
  .content {
    overflow: initial;
    padding: 0 $default-spacing;
  }
}
