// Marges internes et externes des "sections" //

$ev-section-padding: 0 !default;
$ev-section-margin-bottom: 2.5rem !important !default;
$ev-section-margin-left: 0 !default;
$ev-section-margin-right: 0 !default;
$ev-section-margin-top: 0 !default;
$ev-section-margin-small: 0 0 $big-spacing 0 !default;

.ev-section {
  display: flex;
  margin-bottom: $ev-section-margin-bottom;
  margin-left: $ev-section-margin-left;
  margin-right: $ev-section-margin-right;
  margin-top: $ev-section-margin-top;
  max-width: 100vw;
  padding: $ev-section-padding;

  &--column {
    flex-direction: column;
  }

  &__header,
  &__content {
    padding: 0 $ev-section-padding;
  }

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }

  &__content {
    max-width: 100%;
  }

  &__title {
    flex-basis: 0;
    flex-grow: 1;
    line-height: 100%;
  }
}
