.mission {
  display: flex;

  &__content,
  &__gallery,
  &__description,
  &__description-wrapper,
  &__client {
    display: flex;
  }

  &__description,
  &__client {
    flex-direction: column;
  }

  &__description {
    margin-right: 7%;
    width: 73%;

    &-wrapper {
      align-items: flex-start;
      margin-top: 4rem;
    }
  }

  &__client {
    background: $gray-fa;
    box-shadow: 0 $small-spacing $default-spacing -.4rem rgba($black, .5);
    padding: $default-spacing;
    position: relative;
    width: 20%;
  }
}

@import 'media-queries';
