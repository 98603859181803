@mixin pseudo-element {
  content: '';
  display: block;
  position: absolute;
}

// Composants

@mixin arrow(
  $position: before,
  $transform: rotate(0deg),
  $size-1: 0,
  $size-2: 1rem,
  $size-3: .6rem,
  $size-4: 0,
  $border-color: transparent transparent $gray-c transparent,
  $border-color-hover: transparent transparent $blue transparent,
  $margin: 0 .5rem 0 0,
  $margin-before-hover: 0 1rem,
  $margin-after-hover: 0 -2rem 0 1rem
) {
  &::#{$position} {
    border-color: $border-color;
    border-style: solid;
    border-width: $size-1 $size-2 $size-3 $size-4;
    content: '';
    display: block;
    height: 0;
    margin: $margin;
    position: relative;
    transform: $transform;
    transition: all .15s;
    width: 0;
  }

  &:hover,
  &:active,
  &:focus {
    &::#{$position} {
      border-color: $border-color-hover;
    }

    &::before {
      margin: $margin-before-hover;
    }

    &::after {
      margin: $margin-after-hover;
    }
  }
}
