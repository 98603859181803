@import 'mixins';

$image-size-small: 4rem;
$image-size-medium: 7rem;
$image-size-large: 12rem;
$image-size-huge: 20rem;

.image {
  width: auto;

  &__wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;

    .image {
      max-width: 100%;
      width: auto;
    }

    &--small {
      @include image($image-size-small);
    }

    &--medium {
      @include image($image-size-medium);
    }

    &--large {
      @include image($image-size-large);
    }

    &--huge {
      @include image($image-size-huge);
    }

    &--boat {
      &-1,
      &-2,
      &-3,
      &-4 {
        .image {
          width: 100%;
        }
      }

      &-1 {
        width: 20%;
      }

      &-2 {
        width: 30%;
      }

      &-3 {
        width: 50%;
      }

      &-4 {
        top: 3.5vw;
        width: 100%;
      }
    }

    &--client {
      margin-bottom: $medium-spacing;
      overflow: initial;

      .image {
        max-height: 8rem;
        max-width: initial;
      }
    }

    &--team {
      border-radius: 50%;
      box-shadow: 0 .5rem 1rem -.4rem rgba($black, 1) inset;
      max-height: 10vw;
      max-width: 10vw;
      min-height: 10vw;
      min-width: 10vw;
      overflow: initial;
      position: relative;

      .image,
      .icon {
        border-radius: 50%;
        overflow: hidden;
        z-index: -1;
      }

      .icon {
        background-color: $color-alt;
        height: 100%;
        width: 100%;

        &-team {
          max-height: 70%;
          max-width: 70%;
          min-height: 70%;
          min-width: 70%;
        }

        &__shape {
          fill: rgba($white, .15);
        }
      }

      &::before {
        @include pseudo-element;
        border: .15rem dashed $gray-e;
        border-radius: 50%;
        height: 110%;
        width: 110%;
        z-index: 1;
      }
    }
  }

  &__thumbnails {
    padding-bottom: $small-spacing;
  }

  &__link {
    .image {
      transform: scale(1);
      transition: transform 1s;
    }

    &:hover {
      .image {
        transform: scale(1.2);
        transition: transform .25s;
      }
    }
  }
}

@import 'media-queries';
