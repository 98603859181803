.layout {
  min-height: 100vh;
  overflow: hidden;

  &__content {
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    transition: margin-right .25s;

    &::before {
      background-color: $white;
      content: '';
      display: block;
      height: 100%;
      left: calc(4rem - 1px);
      position: fixed;
      top: 0;
      transition: left .25s ease .25s;
      width: 4.5rem;
      z-index: 2;
    }
  }

  &__background {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-width: 100vw;
    position: fixed;

    &-bloc {
      &--1 {
        background-image: $vgradient-gray-1;
        height: 4.5rem;
      }

      &--2 {
        background-image: $vgradient-gray-3;
        flex-grow: 4;
        min-height: 12rem;
      }

      &--3 {
        background-image: $vgradient-blue;
        flex-grow: 1;
      }

      &--4 {
        background-image: $vgradient-gray-2;
        min-height: 12rem;
      }
    }
  }

  .triangle {
    bottom: 0;
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, .3));
    height: 105vh;
    left: calc(1.5rem + 1px);
    position: fixed;
    transform: scaleX(1.5);
    transform-origin: top right;
    transition: left .25s ease .2s;
    width: 2.5rem;

    &-polygon {
      fill: $white;
    }
  }
}

@import 'media-queries';
