@mixin element-full-width {
  &:not(.ev-element--nowrap-s) {
    > .ev-element {
      max-width: 100% !important;
      min-width: 100% !important;
    }
  }

  &--gutter {
    &:not(.ev-element--nowrap-s) {
      left: 0 !important;
      max-width: 100% !important;
      min-width: 100% !important;

      .ev-element {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }

    .ev-element {
      &__wrapper {
        &--gutter {
          margin-top: $ev-element-margin !important;

          &:last-of-type {
            margin-bottom: 0 !important;
          }
        }
      }

      .ev-element {
        .ev-section {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}
