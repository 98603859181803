@font-face {
  font-family: 'Glacial Indifference';
  font-style: normal;
  font-weight: normal;
  src: url('../../../fonts/GlacialIndifference-Regular.woff2') format('woff2'),
       url('../../../fonts/GlacialIndifference-Regular.woff') format('woff'),
       url('../../../fonts/GlacialIndifference-Regular.ttf') format('truetype');
}

$font-family-default: "Glacial Indifference", sans-serif;
$font-family-alt: 'myriad-pro', sans-serif;

body {
  color: $color-main;
  font-family: $font-family-default;
  font-weight: 400;
}
