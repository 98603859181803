@media screen and (max-width: map-get($breakpoints, 'medium')) {
  .mission {
    &__description {
      margin-right: 5%;
      width: 55%;
    }

    &__client {
      width: 40%;
    }
  }
}

@media screen and (max-width: map-get($breakpoints, 'small')) {
  .mission {
    &__gallery {
      padding: 0;
      width: 100%;
    }

    &__description {
      width: 100%;

      &-wrapper {
        flex-wrap: wrap;
        margin-top: 4rem;
      }
    }

    &__client {
      margin-top: 4rem;
      width: 100%;
    }
  }
}