.carousel {
  flex-grow: 2;

  .flickity {
    &-button {
      &:disabled {
        opacity: 0;
      }
    }

    &-page-dots {
      align-items: center;
      bottom: -1.6rem;
      display: flex;
      justify-content: center;

      .dot {
        align-items: center;
        background-color: $gray-a;
        border-radius: 0;
        cursor: pointer;
        display: flex;
        height: .2rem;
        justify-content: center;
        position: relative;
        transform: scale(1);
        transition: all .15s;
        width: 5%;

        &::before {
          @include pseudo-element;
          height: 3rem;
          left: 0;
          top: -1.4rem;
          width: 100%;
        }

        &:hover,
        &.is-selected {
          transform: scale(1.2);
        }

        &:hover {
          background-color: $gray-60;
        }

        &.is-selected {
          background-color: $color-alt !important;
        }
      }
    }

    &-prev-next-button {
      border-radius: 0;
      height: 100%;
      width: $big-spacing;

      .arrow {
        transition: fill .25s;
      }

      &.previous {
        left: -2rem;
      }

      &.next {
        right: -2rem;
      }

      &:hover {
        .arrow {
          fill: $color-alt;
        }
      }
    }
  }
}

@import "media-queries";