.navigation {
  display: flex;

  &--main {
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
    position: fixed;
    right: $small-spacing;
    transition: width .25s ease .2s, right .25s ease .2s;
    width: 4rem;
    z-index: 5;

    &::before,
    &::after {
      @include pseudo-element;
      height: 100%;
    }

    &::before {
      background: rgba($white, .9);
      width: 4rem;
    }

    &::after {
      background-image: linear-gradient(to right, rgba($black, .2), rgba($black, .05), transparent);
      left: 4rem;
      width: .6rem;
      z-index: -1;
    }

    &:hover,
    &:active,
    &:focus {
      right: 0;
      width: 17rem;

      + .content {
        .header {
          transition: height .25s, max-height .25s, width .25s ease .2s;
          width: calc(100% - 21rem);

          &::before {
            transition: left .25s ease 0s;
          }
        }

        .button {
          &--scroll-top {
            opacity: 0;
            right: 12rem;
            transition: bottom .25s ease 0s, right .25s ease 0s, opacity .25s ease 0s;
          }
        }
      }
    }

    .navigation {
      &__link {
        align-items: center;
        display: flex;
        height: 4rem;
        padding: $small-spacing 0 $small-spacing $small-spacing;

        &:hover,
        &:focus,
        &.active {
          .button {
            &__text {
              color: $black;
            }
          }
        }

        &:hover,
        &:focus {
          .icon {
            color: $color-main;
          }
        }

        &.active {
          .icon {
            color: $color-alt !important;
          }
        }

        &--dark-bg {
          .button {
            &__text {
              color: $gray-a;
            }
          }

          &:hover,
          &:focus,
          &.active {
            .button {
              &__text {
                color: $white !important;
              }
            }
          }
        }
      }

      &__element {
        min-height: 4rem;
        overflow: hidden;
      }
    }

    .button {
      &__text {
        align-items: center;
        color: $gray-60;
        display: flex;
        font-family: 'Glacial Indifference', century ghotic, sans-serif;
        font-size: 1.2rem;
        height: 100%;
        margin-left: -.5rem;
        padding-left: $default-spacing;
        transition: color .25s;
        white-space: nowrap;
        width: 100%;
      }

      &--home {
        min-height: $medium-spacing;
      }
    }

    .icon {
      align-items: center;
      color: $gray-a;
      display: flex;
      justify-content: center;
      left: -.5rem;
      min-height: 4rem;
      min-width: 4rem;
      position: relative;
    }

    .form {
      &__field-container {
        &--language {
          margin-left: $small-spacing;
          width: calc(100% - 5.5rem);
        }
      }
    }

    .layout {
      &__background {
        margin-left: 4rem;
        z-index: -1;
      }
    }
  }
}

@import 'media-queries';
