.content {
  background: $white;
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  height: 100%;
  justify-content: space-between;
  margin: 0 $small-spacing 0 4rem;
  min-height: 100vh;
  overflow: hidden;
  padding: 0 4rem;
  position: relative;
  transition: margin-right .25s ease .2s, padding .25s ease .2s;
}

@import 'media-queries';
