@media screen and (max-width: map-get($breakpoints, 'medium')) {
  .layout {
    &__content {
      margin-right: 0;
      transition: margin-right .25s;

      &::before {
        left: 0;
        transition: left .25s ease 0s;
      }
    }

    .triangle {
      left: calc(-2.5rem + 1px);
      transition: left .25s ease 0s;
    }
  }
}