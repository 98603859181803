@import 'mixins';

.button {
  align-items: center;
  display: flex;
  transition: all .15s;

  &--icon {
    @include button-icon;

    &:hover {
      .icon {
        color: $gray-40;
      }
    }
  }

  &--text {
    &:hover,
    &:active,
    &:focus {
      color: $blue;
    }
  }

  &--no-bg,
  &--link,
  &--text {
    .icon {
      color: $color-alt;
    }
  }

  &--medium {
    font-size: 1.2rem;
  }

  &--small {
    font-size: .9rem;
    font-weight: bold;
  }

  &--link-r,
  &--link-b,
  &--link-a {
    &:hover,
    &:active,
    &:focus {
      color: $blue;
    }
  }

  &--link-r {
    @include arrow(
      $size-2: 0,
      $size-4: $default-spacing,
      $margin-before-hover: 0 $default-spacing 0 -2rem,
      $margin-after-hover: 0 0 0 $default-spacing
    );
  }

  &--link-b {
    @include arrow();
  }

  &--link-a {
    @include arrow(
      $margin: 0 0 0 $small-spacing,
      $position: after
    );
  }

  &--close {
    align-self: flex-start;
    color: $gray-a;
    font-size: .9rem;
    margin-left: auto;
    padding-left: $default-spacing;
    position: relative;
    transition: color .25s;

    &::before,
    &::after {
      @include pseudo-element;
      background-color: $gray-a;
      height: $default-spacing;
      left: 0;
      top: .75rem;
      transition: all .25s;
      width: .1rem;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }

    .icon {
      font-size: $default-spacing;
      margin-right: 0;
    }

    &:hover,
    &:focus {
      color: $color-alert;

      &::before,
      &::after {
        background-color: $color-alert;
      }
    }
  }

  &--line {
    flex-wrap: nowrap;
    font-size: $default-spacing;
    line-height: $medium-spacing;
    margin-bottom: $big-spacing;

    &::after {
      border-bottom: .1rem solid $gray-e;
      content: '';
      display: block;
      flex-grow: 2;
      margin-left: $default-spacing;
    }
  }

  &--hud {
    background-color: rgba($white, .9);

    .icon {
      color: $blue;
    }
  }

  &--scroll-top {
    align-items: center;
    background-color: $blue;
    bottom: 2vh;
    box-shadow: 0 .7rem $default-spacing rgba($black, .2);
    display: flex;
    height: $huge-spacing;
    justify-content: center;
    opacity: 0;
    position: fixed;
    right: 6rem;
    transition: bottom .25s ease .35s, right .25s ease .35s, opacity .25s ease .35s, background-color .25s;
    width: $huge-spacing;

    .icon {
      color: $white;
      font-size: $big-spacing;
    }

    &.visible {
      bottom: 5vh;
      opacity: 1;
    }

    &:hover,
    &:active {
      background-color: $gray-40;
    }
  }

  &--return {
    color: $gray-a;
    font-size: .9rem;
  }

  &:not(.button--no-bg):not(.button--text):not(.button--mobile-nav):not(.button--close):not(.button--scroll-top):not(.button--link-b):not(.button--link-a):not(.button--line):not(.button--text) {
    background-color: $gray-33;
    box-shadow: 0 .3rem .75rem -.2rem rgba($black, .3);
    color: $white;
    font-size: 1.1rem;
    justify-content: center;
    min-height: 2.2rem;
    padding: $tiny-spacing $medium-spacing;
    text-align: left;

    .icon {
      aspect-ratio: 1;
      color: $blue;
      font-size: 1.2rem;
      height: 1.25rem;
      padding-bottom: .2rem;
    }

    &:not(:disabled) {
      &:hover,
      &:active {
        background-color: $blue;

        .icon {
          color: $white;
        }
      }
    }

    &:disabled {
      background-color: $gray-33 !important;
      cursor: not-allowed;
    }
  }

  &--mobile-nav {
    .icon {
      height: $medium-spacing;
      margin-right: 0 !important;
      position: relative;
      transition: height .15s;
      width: $big-spacing;

      &__bar,
      &::before,
      &::after {
        background-color: $color-alt;
        height: 2px;
        transition: background-color 0s ease .15s;
        width: $big-spacing;
      }

      &::before,
      &::after {
        @include pseudo-element;
        transition: top .1s ease .15s, bottom .1s ease .15s, transform .15s ease 0s;
      }

      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }
    }

    &:hover {
      &:not(.active) {
        .icon {
          height: $big-spacing;
        }
      }
    }

    &.active {
      .icon {
        &__bar {
          background-color: transparent;
        }

        &::before,
        &::after {
          transition: top .1s ease 0s, bottom .1s ease 0s, transform .15s ease .15s;
        }

        &::before {
          top: 11px;
          transform: rotate(45deg);
        }

        &::after {
          bottom: 11px;
          transform: rotate(-45deg);
        }
      }
    }
  }

  .triangle {
    height: .7rem;
    width: 1.2rem;
  }

  @each $name, $color in $colors {
    &.button {
      &--#{$name} {
        &:not(.button--no-bg):not(.button--text):not(.button--mobile-nav):not(.button--close):not(.button--scroll-top):not(.button--link-b):not(.button--link-a):not(.button--line):not(.button--text) {
          background-color: $color;

          .icon {
            color: $white;
          }

          &:hover,
          &:active {
            background-color: $gray-33;
          }
        }

        &.button {
          &--text {
            color: $color;

            .icon {
              color: $color;
            }

            &:hover,
            &:active,
            &:focus {
              color: $gray-40;

              .icon {
                color: $gray-40;
              }
            }
          }
        }
      }

      &--white {
        &:not(.button--no-bg):not(.button--text):not(.button--mobile-nav):not(.button--close):not(.button--scroll-top):not(.button--link-b):not(.button--link-a):not(.button--line):not(.button--text) {
          color: $gray-40;

          .icon {
            color: $color-alt;
          }

          &:hover,
          &:active {
            background-color: $color-alt;
            color: $white;

            .icon {
              color: $white;
            }
          }
        }
      }
    }
  }

  &:disabled {
    opacity: .5;
  }
}

// Toggle button

$toggleArrowWidth: 7px;
$toggleArrowDepth: 8px;

[data-toggle-large],
[data-toggle-small],
[data-toggle-medium] {
  @include arrow(
    $position: after,
    $margin: 0 0 0 auto,
    $size-1: $small-spacing,
    $size-2: $small-spacing,
    $size-3: 0,
    $size-4: $small-spacing,
    $border-color: $gray-c transparent transparent transparent,
    $border-color-hover: $blue transparent transparent transparent,
    $margin-before-hover: 0 $small-spacing 0 0,
    $margin-after-hover: 0 0 0 auto
  );

  &::after {
    display: none;
  }

  &.open {
    &::after {
      transform: rotate(-180deg);
    }
  }
}

[data-toggle-large] {
  &::after {
    display: block;
  }
}

@import 'media-queries';
