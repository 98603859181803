.form {
  &__field,
  &__search {
    width: 100%;
  }

  &__label {
    color: $blue;
    display: inline-flex;
    margin-bottom: $small-spacing;
  }

  &__error {
    color: $color-alert;
    margin-bottom: $small-spacing;
  }

  &__field {
    background-color: $gray-fa;
    border: 1px solid $gray-e;
    box-shadow: 0 .75rem $default-spacing -.5rem rgba($black, .3);
    font-family: 'myriad-pro', sans-serif;
    font-size: 1.1rem;
    min-height: 2.8rem;
    padding: $small-spacing $default-spacing;
    transition: all .25s;

    &-container {
      display: flex;
      flex-direction: column;
      margin-top: $medium-spacing;
      position: relative;

      &--checkbox,
      &--radio,
      &--inline {
        align-items: center;
        flex-direction: row;
      }

      &--inline {
        display: flex;

        .form {
          &__label {
            align-items: center;
            display: flex;
            margin-bottom: 0;
            margin-right: $small-spacing;
          }

          &__field {
            flex-grow: 2;
            width: auto;

            &-container {
              margin-bottom: 0;
            }
          }
        }
      }

      &--select {
        option {
          min-height: 2.8rem;
        }
      }

      &--checkbox {
        &:not(:last-of-type) {
          margin-right: $default-spacing;
        }

        &-only {
          max-width: $default-spacing;

          .form {
            &__label {
              color: transparent;
              max-width: 18px;
              min-height: 18px;
              min-width: 18px;
              overflow: hidden;
            }
          }
        }
      }

      &--radio {
        margin-right: $big-spacing;

        .form {
          &__field {
            margin: 0 $small-spacing 0 0;
            min-height: unset;
            width: auto;
          }

          &__label {
            margin-bottom: 0;
          }
        }
      }

      &--range {
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        .form {
          &__label,
          &__label-wrapper {
            width: 100%;
          }

          &__label {
            &-wrapper {
              display: flex;
              justify-content: space-between;

              .form {
                &__label {
                  width: calc(50% - 1rem);
                }
              }
            }
          }

          &__field {
            max-width: calc(50% - 1rem);
          }
        }

        .separator {
          margin: 0;
        }
      }

      &--language {
        .form {
          &__field {
            min-height: 2.2rem;
            padding: $tiny-spacing $default-spacing;
          }
        }
      }

      &--captcha {
        border: 2px dashed $color-alt;
        flex-wrap: wrap;
        justify-content: center;
        padding: $default-spacing $huge-spacing;

        .form {
          &__label {
            font-size: 1.1rem;
            padding: $default-spacing;
            text-align: center;
          }
        }
      }
    }

    &--textarea {
      font-family: 'Arial', sans-serif;
      line-height: 1.4rem;
      min-height: 10rem;
      padding-bottom: $small-spacing;
      padding-top: $small-spacing;
      resize: none;
    }

    &--file,
    &--image {
      // TODO : Temporaire avant ajout select2
      background-color: transparent;
      border: 0;
      padding: .35rem 0;
    }

    &:focus {
      background-color: $white;
      border-color: $blue;
      box-shadow: 0 0 0 0 rgba($black, 0);
      outline: 0;
    }

    &--select {
      &:disabled {
        opacity: .5;
      }

      option {
        zoom: 1.25;
      }
    }
  }

  &--inline {
    display: flex;
    flex-wrap: wrap;

    .form {
      &__field-container {
        margin-bottom: 0;

        &--radio {
          margin-bottom: $tiny-spacing;
          margin-top: $tiny-spacing;
        }
      }
    }
  }
}

[type='checkbox'],
[type='radio'] {
  left: 0;
  opacity: 0;
  position: absolute;

  + .form {
    &__label {
      align-items: center;
      cursor: pointer;
      display: flex;
      position: relative;
      transition: color .25s;
      user-select: none;
      white-space: nowrap;

      &::before,
      &::after {
        content: '';
        display: block;
        margin-right: $small-spacing;
      }

      &::before {
        background-color: $gray-fa;
        outline: 1px solid $gray-a;
        position: relative;
        transition: border-color .15s, background-color .15s;
      }

      &::after {
        background-color: $color-alt;
        position: absolute;
        transform: scale(1);
        transform-origin: center;
        transition: transform .15s, opacity .15s;
      }
    }
  }

  &:not(:checked) {
    + .form {
      &__label {
        color: $gray-a;

        &::after {
          opacity: 0;
          transform: scale(0);
        }
      }
    }

    &:hover {
      + .form {
        &__label {
          color: $color-main;

          &::after {
            opacity: .5;
            transform: scale(.4);
          }
        }
      }
    }
  }

  &:checked {
    + .form {
      &__label {
        color: $color-main;

        &::before {
          background-color: $color-alt;
          border-color: $color-alt;
          outline-color: $color-alt;
        }

        &::after {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
}

[type='checkbox'] {
  + .form {
    &__label {
      &::before,
      &::after {
        min-height: 16px;
        min-width: 16px;
      }
    }
  }
}

[type='radio'] {
  + .form {
    &__label {
      transition: color .25s;

      &::before,
      &::after {
        min-height: 14px;
        min-width: 14px;
      }

      &::before {
        border: 1px solid $gray-a;
        outline: 0;
      }

      &::after {
        left: 1px;
      }
    }
  }
}

@import 'media-queries';
