@import 'mixins';
$text-shadow-no-bg: 0 1px 3px $black;

.ev-text {

  // Taille //

  @each $name, $font-size in $font-sizes {
    &--#{$name} {
      font-size: $font-size !important;
    }
  }

  // Graisse //

  &--regular {
    font-weight: $font-weight-normal !important;
  }

  &--bold {
    font-weight: $font-weight-bold !important;
  }

  // Style //

  &--italic {
    font-style: italic !important;
  }

  &--no-style {
    font-style: normal !important;
  }

  // Transformation //

  &--capitalize {
    text-transform: capitalize !important;
  }

  &--uppercase {
    text-transform: uppercase !important;
  }

  &--lowercase {
    text-transform: lowercase !important;
  }

  &--no-transformation {
    text-transform: initial !important;
  }

  // Décoration //

  &--underline {
    text-decoration: underline !important;
  }

  &--overline {
    text-decoration: overline !important;
  }

  &--no-decoration {
    text-decoration: none !important;
  }

  // Ligne unique + points de suspension //

  &--ellipsis {
    @include typography-ellipsis;
  }

  // Comportement de retour à la ligne du texte //

  &--nowrap {
    white-space: nowrap !important;
  }

  &--wrap {
    white-space: normal !important;
  }

  // Alignement du texte //

  &--left {
    justify-content: left !important;
    text-align: left !important;
  }

  &--right {
    justify-content: right !important;
    text-align: right !important;
  }

  &--center {
    justify-content: center !important;
    text-align: center !important;
  }

  &--justify {
    text-align: justify !important;
    text-justify: auto !important;
  }

  // Ombre //

  &--no-shadow {
    text-shadow: none !important;
  }
}

// Alignement du texte en fonction des différentes tailles d'écrans //

@import 'media-queries';
