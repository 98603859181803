.header {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: 4.5rem;
  left: 0;
  max-height: 4.5rem;
  position: fixed;
  transition: height .25s, max-height .25s, width .25s ease .2s;
  width: 100%;
  z-index: 4;

  &::before {
    @include pseudo-element;
    background-color: rgba($white, .9);
    height: 4.5rem;
    left: 4rem;
    transition: left .25s ease .2s;
    width: 100%;
    z-index: -1;
  }

  .button {
    &--mobile-nav {
      background: transparent;
      height: 100%;
      margin-left: auto;
      opacity: 0;
      position: relative;
      right: -5rem;
      top: -5rem;
      transition: right .25s ease 0s, opacity .25s ease 0s;
      width: $huge-spacing;
      z-index: 1;

      .icon {
        &::before {
          color: $color-alt;
          font-size: 2rem;
        }
      }
    }
  }
}

@import 'media-queries';
