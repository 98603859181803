@media screen and (max-width: map-get($breakpoints, 'medium')) {
  .ev-element {
    &__wrapper {
      &--wrap-medium {
        @include element-full-width;
      }

      &--gutter {
        &.ev-element {
          &__wrapper {
            &--wrap-medium {
              > .ev-element {
                max-width: calc(100% - #{$ev-element-margin * 2}) !important;
                min-width: calc(100% - #{$ev-element-margin * 2}) !important;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: map-get($breakpoints, 'small')) {
  .ev-element {
    &__wrapper {
      @include element-full-width;
    }
  }
}
