.flash {
  align-items: center;
  background-color: $white;
  display: flex;
  margin-bottom: $small-spacing;

  &__text {
    color: $white;
    margin-right: auto;
    padding: $small-spacing $default-spacing;
  }

  &:last-of-type {
    margin-bottom: $default-spacing;
  }

  &--success {
    background-color: $color-success;
  }

  &--error {
    background-color: $color-alert;
  }
}
