@media screen and (max-width: map-get($breakpoints, 'small')) {
  .form {
    &__field-container {
      &--captcha {
        .form {
          &__label {
            margin-right: 0;
            padding-top: 0;
          }
        }
      }
    }
  }
}