// LARGE +
/*@media screen and (min-width: map-get($breakpoints, 'medium')) {
  [data-toggle-section-small],
  [data-toggle-section-medium] {
    display: flex !important;
  }
}*/

// MEDIUM/SMALL
@media screen and (max-width: map-get($breakpoints, 'medium')) {
  [data-toggle-section-medium] {
    display: none;
  }
}

// MEDIUM +
/*@media screen and (min-width: map-get($breakpoints, 'small')) {
  [data-toggle-section],
  [data-toggle-section-medium] {
    display: flex !important;
  }
}*/

// SMALL
@media screen and (max-width: map-get($breakpoints, 'small')) {
  [data-toggle-section-small] {
    display: none;
  }
}