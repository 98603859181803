@media screen and (max-width: map-get($breakpoints, 'medium')) {
  .ev {
    &-direction {
      &--m {
        &-row {
          flex-direction: row !important;
        }

        &-column {
          flex-direction: column !important;
        }
      }
    }

    &-justify {
      &--m {
        &-center {
          justify-content: center !important;
        }

        &-start {
          justify-content: flex-start !important;
        }

        &-end {
          justify-content: flex-end !important;
        }

        &-between {
          justify-content: space-between !important;
        }

        &-around {
          justify-content: space-around !important;
        }

        &-evenly {
          justify-content: space-evenly !important;
        }
      }
    }

    &-align {
      &--m {
        &-center {
          align-items: center !important;
        }

        &-start {
          align-items: flex-start !important;
        }

        &-end {
          align-items: flex-end !important;
        }

        &-baseline {
          align-items: baseline !important;
        }

        &-stretch {
          align-items: stretch !important;
        }
      }
    }
  }

  .ev-wrap {
    &--m {
      flex-wrap: wrap !important;
    }
  }

  .ev-nowrap {
    &--m {
      flex-wrap: nowrap !important;
    }
  }

  @for $i from 0 through 7 {
    .ev-grow {
      &--m-#{$i} {
        flex-grow: #{$i} !important;
      }
    }
  }
}

@media screen and (min-width: map-get($breakpoints, 'small')) {
  @include test('vw');
}

@media screen and (max-width: map-get($breakpoints, 'small')) {
  .ev {
    &-direction {
      &--s {
        &-row {
          flex-direction: row !important;
        }

        &-column {
          flex-direction: column !important;
        }
      }
    }

    &-justify {
      &--s {
        &-center {
          justify-content: center !important;
        }

        &-start {
          justify-content: flex-start !important;
        }

        &-end {
          justify-content: flex-end !important;
        }

        &-between {
          justify-content: space-between !important;
        }

        &-around {
          justify-content: space-around !important;
        }

        &-evenly {
          justify-content: space-evenly !important;
        }
      }
    }

    &-align {
      &--s {
        &-center {
          align-items: center !important;
        }

        &-start {
          align-items: flex-start !important;
        }

        &-end {
          align-items: flex-end !important;
        }

        &-baseline {
          align-items: baseline !important;
        }

        &-stretch {
          align-items: stretch !important;
        }
      }
    }
  }

  .ev-wrap {
    &--s {
      flex-wrap: wrap !important;
    }
  }

  .ev-nowrap {
    &--s {
      flex-wrap: nowrap !important;
    }
  }

  @for $i from 0 through 7 {
    .ev-grow {
      &--s-#{$i} {
        flex-grow: #{$i} !important;
      }
    }
  }

  @include test('rem');
}
