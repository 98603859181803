@media screen and (max-width: map-get($breakpoints, 'medium')) {
  .header {
    width: 100%;

    &::before {
      left: 0;
      transition: left .25s ease .0s;
      width: 100vw;
    }

    .button {
      &--mobile-nav {
        opacity: 1;
        right: 0;
        top: 0;
        transition: right .25s ease .25s, opacity .25s ease .25s;
      }
    }
  }
}

@media screen and (max-width: map-get($breakpoints, 'small')) {
  .header {
    height: $huge-spacing;
    max-height: $huge-spacing;

    &::before {
      height: $huge-spacing;
    }

    .logo {
      &__picto {
        width: $huge-spacing;

        .image {
          height: 2.2rem;
        }
      }

      &__text {
        .image {
          height: .75rem;
        }
      }
    }

    .button {
      &--mobile-nav {
        width: 2.5rem;
      }
    }
  }
}