.section {
  flex-direction: column;
  opacity: 0;
  padding: 0 18vw 0 15vw;
  position: relative;

  &:nth-of-type(3n+1) {
    top: -5rem;
    transition: opacity .15s ease 0s, top .15s ease 0s, padding .25s, margin-top .5s;
  }

  &:nth-of-type(3n+2) {
    left: -5rem;
    transition: opacity .15s ease .15s, left .15s ease .15s, padding .25s;
  }

  &:nth-of-type(3n+3) {
    bottom: -5rem;
    transition: opacity .15s ease .3s, bottom .15s ease .3s, padding .25s;
  }

  &:not(:first-of-type) {
    margin-top: 5vw;
  }

  &--medium {
    padding: 0 11vw 0 8vw;
  }

  &--large {
    padding: 0 6rem 0 $huge-spacing;
  }

  &--services {
    min-height: 40vw;

    &.active {
      .text {
        &--intro {
          opacity: 0;
          transform: scale(0);
          transition: left .25s ease .1s, right .25s ease .1s, opacity .25s ease .1s, transform 0s ease .5s;
        }
      }

      .button {
        &--view-more {
          bottom: -5rem;
          opacity: 0;
          transform: scale(0);
          transition: bottom .25s ease .2s, opacity .25s ease .2s, transform 0s ease .5s;
        }
      }

      .picto {
        transform: scale(1);
        transition: right .25s ease 0s, left .25s ease 0s, transform .25s ease .5s;

        .image {
          opacity: .5;
          transform: scale(1);
          transform-origin: top left;
          transition: height .25s ease .25s, transform .25s ease 0s, opacity .75s ease .25s;
        }
      }

      .service {
        &__navigation {
          height: 5.5vw;
          left: -8rem;
          transition: height .25s ease .25s, left .25s ease .25s, width .25s ease .25s, margin-left .25s ease .25s, margin-top .25s ease 0s, padding-left .25s ease .25s;
          width: 0;

          &::before {
            right: -3rem;
            top: -.5rem;
            width: 17rem;
          }

          .title {
            font-size: 1.1vw;
            line-height: 1.5vw;
            margin-left: 0;
            min-height: 5.5vw;
            opacity: 1;
            padding-bottom: 1vw;
            transition: font-size .25s ease 0s, line-height .25s ease 0s, padding .25s ease 0s, min-height .25s ease .5s, margin-left .15s ease 0s;

            &__text {
              margin-right: $big-spacing;
              opacity: .5;
              transform: scaleX(1);
              transition: opacity .75s ease .25s, transform 0s ease 0s, margin-right .25s ease 0s;
            }
          }

          .picto {
            top: .9vw;
            transition: top .25s ease .5s, left .25s ease 0s, right .25s ease 0s, transform .25s ease 0s;
          }

          &.active,
          &:hover {
            .picto {
              .image {
                opacity: 1;
                transition: height .25s ease .5s, transform .25s ease 0s, opacity .25s;
              }
            }

            .title {
              &__text {
                opacity: 1;
                transition: opacity .25s, transform 0s ease 0s, margin-right .25s ease 0s;
              }
            }
          }

          &.active {
            height: 0;
            left: 0;
            transition: height .25s ease 0s, left .25s ease 0s, margin-top .25s ease .25s;

            .picto {
              transition: top .25s ease .5s, left .25s ease .5s, right .25s ease .5s, transform .25s ease .5s;

              .image {
                transform: scale(2);
                transition: height .25s ease .25s, transform .25s ease .25s, opacity .25s;
              }
            }

            .title {
              margin-left: $default-spacing;

              &__text {
                opacity: 0;
                transform: scaleX(0);
                transition: opacity .15s ease 0s, transform 0s ease .25s;
              }
            }
          }

          &:not(.service__navigation--right) {
            .text {
              &--intro {
                right: 5rem;
              }
            }

            .picto {
              left: calc(100% - 1.5rem);
            }
          }

          &--right {
            margin-left: 0;
            padding-left: 0;

            &::before {
              right: -4.5rem;
            }

            .text {
              &--intro {
                left: 5rem;
              }
            }

            .picto {
              right: calc(100% - 4.5vw);
            }
          }
        }
      }
    }

    &:not(.active) {
      .service {
        &__navigation {
          &:hover {
            .picto {
              transform: scale(4.5);
            }
          }
        }
      }
    }
  }

  &--line {
    &::after {
      background-color: $gray-e;
      bottom: 0;
      content: '';
      display: block;
      height: 2px;
      left: 0;
      position: relative;
      width: 100%;
    }
  }
}

@import 'media-queries';
