// Colors //

$transparent: transparent;
$inherit: inherit;
$white: #fff;
$black: #000;
$gray-10: #101010;
$gray-1a: #1a1a1a;
$gray-20: #202020;
$gray-25: #252525;
$gray-33: #333;
$gray-40: #404040;
$gray-4d: #4d4d4d;
$gray-50: #505050;
$gray-60: #606060;
$gray-80: #808080;
$gray-90: #909090;
$gray-a: #aaa;
$gray-b: #bbb;
$gray-c: #ccc;
$gray-d: #ddd;
$gray-e: #eee;
$gray-e7: #e7e7e7;
$gray-fa: #fafafa;
$green: #28c785;
$orange: #f08a24;
$red: #fd2c39;
$blue-lighter: #70b3ff;
$blue: #6794F8;
$blue-darker: #6284f4;

// Horizontal gradients //

$hgradient-gray-1: linear-gradient(to left, $gray-4d, $gray-33);
$hgradient-gray-2: linear-gradient(to left, $gray-33, $gray-1a);
$hgradient-gray-3: linear-gradient(to left, $gray-e7, $gray-c);
$hgradient-blue: linear-gradient(to left, $blue-darker, $blue-lighter);

// Vertical gradients //

$vgradient-gray-1: linear-gradient($gray-4d, $gray-33);
$vgradient-gray-2: linear-gradient($gray-33, $gray-1a);
$vgradient-gray-3: linear-gradient($gray-e7, $gray-c);
$vgradient-blue: linear-gradient($blue-lighter, $blue-darker);


// Variables //

$color-default: $gray-40;
$color-main: $gray-40;
$color-alt: $blue;
$color-success: $green;
$color-warning: $orange;
$color-alert: $red;

// Color table //

$colors: (
  'inherit': $inherit,
  'transparent': $transparent,
  'white': $white,
  'black': $black,
  'alert': $color-alert,
  'warning': $color-warning,
  'success': $color-success,
  'default': $color-default,
  'main': $color-main,
  'alt': $color-alt,
  'gray-10': $gray-10,
  'gray-20': $gray-20,
  'gray-25': $gray-25,
  'gray-33': $gray-33,
  'gray-40': $gray-40,
  'gray-50': $gray-50,
  'gray-60': $gray-60,
  'gray-80': $gray-80,
  'gray-90': $gray-90,
  'gray-a': $gray-a,
  'gray-b': $gray-b,
  'gray-c': $gray-c,
  'gray-d': $gray-d,
  'gray-e': $gray-e,
  'gray-fa': $gray-fa,
  'blue': $blue,
  'green': $green,
  'orange': $orange,
  'red': $red,
);

// Gradient table //

$gradients: (
  'hgray-1': $hgradient-gray-1,
  'hgray-2': $hgradient-gray-2,
  'hgray-3': $hgradient-gray-3,
  'hblue': $hgradient-blue,
  'vgray-1': $vgradient-gray-1,
  'vgray-2': $vgradient-gray-2,
  'vgray-3': $vgradient-gray-3,
  'vblue': $vgradient-blue,
);
