@import 'animations';

$serviceMainDelay: .5s;

.service {
  display: flex;

  &__detail {
    display: flex;
    flex-direction: column;
    flex-grow: 2;

    .title {
      &--big {
        text-align: left;
      }
    }
  }

  &__navigation {
    display: flex;
    flex-direction: column;
    height: 20vw;
    left: 0;
    position: relative;
    transition: height .25s ease 0s, left .25s ease 0s, width .25s ease 0s, margin-left .25s ease 0s, padding-left .25s ease 0s, border-color .25s;
    width: 50%;

    .title {
      margin-bottom: $small-spacing;
      min-height: 0;
      padding-bottom: 0;
      transition: font-size .25s ease 0s, line-height .25s ease 0s, padding .25s ease 0s, min-height .25s ease 0s, margin-left .15s ease 0s;

      &__text {
        margin-right: 0;
        transition: margin-right .25s ease 0s, opacity .25s;
        white-space: nowrap;
      }
    }

    .text,
    .button {
      position: relative;
    }

    .text {
      &--intro {
        font-size: $default-spacing;
        margin-bottom: $default-spacing;
        opacity: 1;
        transform: scale(1);
        transition: left .25s ease .25s, right .25s ease .25s, opacity .25s ease .25s, transform 0s ease 0s;
      }
    }

    .button {
      &--view-more {
        bottom: 0;
        opacity: 1;
        transform: scale(1);
        transition: color .25s, bottom .25s ease .4s, opacity .25s ease .4s, transform 0s ease 0s;
      }
    }

    .picto {
      position: absolute;
      top: .5vw;
      transform: scale(4);
      transform-origin: top left;
      transition: top .25s ease 0s, left .25s ease 0s, right .25s ease 0s, transform .25s ease 0s;
      z-index: -1;

      .image {
        height: 4.5vw;
        transition: opacity .25s ease 0s;
        width: auto;
      }
    }

    &::before {
      @include pseudo-element;
      height: 100%;
      width: calc(100% + 14vw);
    }

    &:not(.service__navigation--right) {
      padding-right: $medium-spacing;

      .title {
        justify-content: flex-end;
        text-align: right;
      }

      .text {
        &--intro {
          justify-content: flex-end;
          right: 0;
          text-align: right;
        }
      }

      .picto {
        left: calc(100% + 1.5rem);
      }

      .button {
        margin-left: auto;
      }

      &:hover {
        .picto {
          left: calc(100% + 2rem);
        }
      }
    }

    &--right {
      margin-left: 50%;
      padding-left: $medium-spacing;

      .title {
        text-align: left;
      }

      .text {
        &--intro {
          left: 0;
        }
      }

      .picto {
        right: calc(100% + 1.5rem - 7vw);
        transform-origin: top right;
      }

      &::before {
        right: 0;
      }
    }
  }
}

@import 'media-queries';
