@media screen and (min-width: map-get($breakpoints, 'medium')) {
  .service {
    &__detail {
      opacity: 0;
      position: absolute;
      top: 0;
      transform: scale(0);
      transition: opacity .15s ease 0s, transform 0s ease .25s, margin-left .25s ease 0s;
      width: calc(100% - 34vw);

      .title {
        &--big {
          height: 6.5vw;
          margin-left: calc(5.5vw);
          transition: margin-left .25s ease 0s;
          width: calc(100% - calc(5.5vw + 2rem));
        }
      }
    }

    &__navigation {
      &.active {
        + .service {
          &__detail {
            margin-left: 0;
            opacity: 1;
            transform: scale(1);
            transition: opacity 1s ease .25s, transform 0s ease 0s, margin-left .25s ease .25s;

            .title {
              &--big {
                margin-left: calc(5.5vw + 2rem);
                margin-top: $small-spacing;
                transition: margin-left .25s ease .25s;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: map-get($breakpoints, 'medium')) {
  .service {
    flex-direction: column;

    &__detail {
      margin: $default-spacing 0 $huge-spacing;
      position: relative;
    }

    &__navigation {
      align-items: center;
      border-top: 2px solid $gray-e;
      flex-direction: row;
      height: initial;
      margin-left: 0;
      min-height: 12vw;
      padding: 0 $default-spacing !important;
      width: 100%;

      &--right {
        &::before {
          right: initial;
        }
      }

      .picto {
        left: 0 !important;
        margin-left: 1.5vw;
        order: 1;
        right: initial !important;
        top: calc(50% - 2.5vw);
        transform: scale(1.5);
        transform-origin: center;

        .image {
          height: 7vw;
          padding-top: 1vw;
        }
      }

      .title {
        justify-content: flex-start !important;
        margin-bottom: 0;
        padding-left: 8vw;
        text-align: left !important;
      }

      > .text,
      > .button {
        display: none;
      }
    }

    &__detail {
      .title {
        &--big {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: map-get($breakpoints, 'small')) {
  .service {
    &__navigation {
      min-height: 7rem;

      .title {
        padding-left: 4rem;
      }

      .picto {
        margin-left: $default-spacing;
        top: calc(50% - 1rem);

        .image {
          height: 4rem;
        }
      }
    }
  }
}