.gallery {
  flex-grow: 2;

  .image {
    &__wrapper {
      align-items: center;
      background: #aaa;
      display: flex;
      height: auto;
      justify-content: center;
      max-height: 50vw;
      overflow: hidden;
      width: 100%;

      .image {
        min-height: 100%;
        min-width: 100%;
      }
    }
  }
}

@import "media-queries";