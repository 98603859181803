@media screen and (max-width: map-get($breakpoints, 'medium')) {
  .section {
    padding: 0 !important;

    &__content {
      &--home {
        &-commercial {
          margin-top: $big-spacing;
        }
      }
    }

    &--services {
      margin-top: 5rem;

      &.active {
        .service {
          &__navigation {
            left: 10rem;

            .title {
              font-size: $default-spacing;

              &__text {
                margin-right: $default-spacing;
              }
            }

            &.active {
              left: 16rem;
            }
          }
        }
      }

      &:not(.active) {
        .service {
          &__navigation {
            &:hover {
              .picto {
                transform: scale(1.75);
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: map-get($breakpoints, 'small')) {
  .section {
    &__content {
      &--home {
        &-commercial {
          margin-top: 0;
        }
      }
    }

    &:not(:first-of-type) {
      margin-top: 10vw;
    }
  }
}