.icon {
  align-items: center;
  color: currentColor;
  display: inline-flex;
  height: $medium-spacing;
  justify-content: center;
  position: relative;
  transition: all .15s;
  width: $medium-spacing;

  svg {
    display: block;
    fill: currentColor;
    height: 100%;
    transition: all .2s;
    width: 100%;
  }

  &--before {
    &::before,
    svg {
      margin-right: $default-spacing;
    }
  }

  &--after {
    &::before,
    svg {
      margin-left: $small-spacing;
    }
  }

  svg,
  .image {
    max-height: $medium-spacing;
    max-width: $medium-spacing;
    min-height: $medium-spacing;
    min-width: $medium-spacing;
  }

  &--cv {
    svg,
    .image {
      max-height: $big-spacing;
      max-width: $big-spacing;
      min-height: $big-spacing;
      min-width: $big-spacing;
    }
  }

  &--huge {
    &::before {
      font-size: $huge-spacing;
    }
  }

  &--big {
    &::before {
      font-size: $big-spacing;
    }
  }

  &--medium {
    &::before {
      font-size: $medium-spacing;
    }
  }

  &--tiny {
    svg,
    .image {
      height: 1.2rem;
      width: 1.2rem;
    }
  }

  @each $name, $color in $colors {
    &--#{$name} {
      &::before {
        color: $color;
      }
    }
  }

  + .button {
    &__text {
      padding-left: .75rem;
    }
  }
}

@import 'media-queries';
