@media screen and (max-width: map-get($breakpoints, 'medium')) {
  .list {
    &--medium {
      .list {
        &__element {
          font-size: $default-spacing;
          padding-bottom: .75rem;
        }
      }
    }
  }
}

@media screen and (max-width: map-get($breakpoints, 'small')) {
  .list {
    &--medium {
      .list {
        &__element {
          font-size: .9rem;
          padding-bottom: $small-spacing;
        }
      }
    }

    &--small {
      .list {
        &__element {
          font-size: .8rem;
          line-height: 1.3rem;
        }
      }
    }

    &--tags {
      .list {
        &__link {
          font-size: .8rem;
        }
      }
    }
  }
}
