@media screen and (max-width: map-get($breakpoints, 'medium')) {
  .navigation {
    &--main {
      right: 0;
      transition: width .25s ease 0s, right .25s ease 0s;
      width: 0;
    }

    &--mobile {
      background: rgba($black, .95);
      display: flex;
      flex-direction: column;
      height: 100vh;
      justify-content: space-evenly;
      opacity: 0;
      overflow: hidden;
      position: fixed;
      right: -100%;
      top: 0;
      transition: opacity .25s, right .25s;
      width: 100%;

      .navigation {
        &__link {
          align-items: center;
          color: $white;
          display: flex;
          font-size: 1.2rem;
          padding: $default-spacing;

          .icon {
            margin-right: $default-spacing;

            &__shape {
              fill: $gray-a;
              transition: fill .25s;
            }
          }

          .button {
            &__text {
              padding-left: 0;
            }
          }

          &.active,
          &:hover,
          &:active {
            color: $white;

            .icon {
              &__shape {
                fill: $color-alt;
              }
            }
          }

          &:hover,
          &:active {
            .icon {
              &__shape {
                fill: $white;
              }
            }
          }

          &.active {
            .icon {
              &__shape {
                fill: $color-alt;
              }
            }
          }
        }
      }

      .icon {
        svg,
        .image {
          height: $medium-spacing;
          width: $medium-spacing;
        }
      }

      .form {
        &__field-container {
          &--language {
            min-width: 8rem;
          }
        }
      }

      &.active {
        opacity: 1;
        right: 0;
      }
    }
  }
}

@media screen and (max-width: map-get($breakpoints, 'small')) {
  .navigation {
    &--mobile {
      .form {
        &__field-container {
          &--language {
            flex-grow: 2;
          }
        }
      }
    }
  }
}