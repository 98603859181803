@keyframes OpenServiceRightTitle {
  0%   {
    justify-content: flex-start;
    opacity: 1;
    text-align: left;
  }

  20%  {
    opacity: 0;
  }

  49%  {
    justify-content: flex-start;
    text-align: left;
  }

  50%  {
    justify-content: flex-end;
    opacity: 0;
    text-align: right;
  }

  100% {
    justify-content: flex-end;
    opacity: 1;
    text-align: right;
  }
}

@keyframes CloseServiceRightTitle {
  0%   {
    justify-content: flex-end;
    opacity: 1;
    text-align: right;
  }

  24%  {
    justify-content: flex-end;
    opacity: 0;
    text-align: right;
  }

  80%  {
    justify-content: flex-start;
    opacity: 0;
    text-align: left;
  }

  100% {
    justify-content: flex-start;
    opacity: 1;
    text-align: left;
  }
}