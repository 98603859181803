$button-icon-size: $medium-spacing;

@mixin button-icon {
  height: $button-icon-size;
  width: $button-icon-size;

  .image {
    height: 100%;
    width: 100%;
  }
}
