@media screen and (max-width: map-get($breakpoints, 'medium')) {
  .image {
    &__wrapper {
      &--boat {
        &-1 {
          width: 11%;
        }

        &-2 {
          width: 14%;
        }

        &-3 {
          width: 25%;
        }

        &-4 {
          top: -1.25vw;
          width: 50%;
        }
      }
    }
  }
}

@media screen and (max-width: map-get($breakpoints, 'small')) {
  .image {
    max-width: 100%;

    &__wrapper {
      &--boat {
        &-1 {
          width: 20%;
        }

        &-2 {
          width: 30%;
        }

        &-3 {
          width: 50%;
        }
      }

      &--team {
        max-height: 26vw;
        max-width: 26vw;
        min-height: 26vw;
        min-width: 26vw;
      }
    }
  }
}
