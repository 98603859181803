.page {
  padding: 7rem 0 $huge-spacing;
  position: relative;
  top: 0;
  width: 100%;
  z-index: -1;

  &.active {
    .section {
      opacity: 1;

      &:nth-of-type(3n+1) {
        top: 0;
        transition: opacity .15s ease .35s, top .15s ease .35s, padding .25s, margin-top .5s ease .25s;
      }

      &:nth-of-type(3n+2) {
        left: 0;
        transition: opacity .15s ease .55s, left .15s ease .55s, padding .25s;
      }

      &:nth-of-type(3n+3) {
        bottom: 0;
        transition: opacity .15s ease .75s, bottom .15s ease .75s, padding .25s;
      }
    }
  }

  &__wrapper {
    position: relative;
    transition: width .25s, margin .25s;
    width: 100%;
    z-index: 3;

    &::before {
      @include pseudo-element;
      background-color: $white;
      height: 100vh;
      left: 0;
      opacity: 1;
      top: 0;
      transition: opacity .5s;
      width: 100%;
    }
  }

  &--services {
    .section {
      padding: 0 18vw 0 16vw;
    }
  }

  &--missions {
    .mosaic {
      &--masonry {
        min-height: 70vh;
      }
    }
  }
}

@import 'media-queries';