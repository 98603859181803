.separator {
  align-items: center;
  display: flex;

  &--slash {
    margin: 0 $default-spacing;

    &::before {
      content: '/';
    }

    &.separator {
      &--colored {
        color: $color-alt;
      }
    }
  }

  &--line {
    &-horizontal,
    &-vertical,
    &-small,
    &-tiny {
      background: $gray-e;
    }

    &-horizontal,
    &-small,
    &-tiny {
      background: $gray-e;
      height: 2px;
      margin-bottom: $big-spacing;
      margin-top: $big-spacing;
    }

    &-horizontal {
      width: 100%;
    }

    &-small {
      width: 10%;
    }

    &-tiny {
      width: $medium-spacing;
    }

    &-vertical {
      height: 100%;
      margin-left: $big-spacing;
      margin-right: $big-spacing;
      width: 2px;
    }
  }

  &--blue {
    background: $blue;
  }

  &--center {
    margin-left: auto;
    margin-right: auto;
  }
}

@import 'media-queries';
