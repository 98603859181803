@media screen and (max-width: map-get($breakpoints, 'medium')) {
  .button {
    &--scroll-top {
      right: $default-spacing;

      &.visible {
        bottom: 2vh;
      }
    }
  }

  [data-toggle-small],
  [data-toggle-medium] {
    padding: $small-spacing 0;
  }

  [data-toggle-medium] {
    &::after {
      display: block;
    }
  }
}

@media screen and (max-width: map-get($breakpoints, 'small')) {
  .button {
    &--scroll-top {
      right: $small-spacing;
    }

    &--return {
      font-size: .85rem;
    }

    &:not(.button--scroll-top) {
      width: 100%;
    }
  }

  [data-toggle-small] {
    &::after {
      display: block;
    }
  }
}
