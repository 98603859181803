.mosaic {
  max-width: 100%;
  position: relative;

  &__element {
    &-link {
      .image {
        min-height: 100%;
        min-width: 100%;
        object-fit: cover;
        transform: scale(1.02);
        transition: transform 1s;
      }

      &:hover {
        .image {
          transform: scale(1.2);
        }

        .mosaic {
          &__slide {
            opacity: 1;
            transform: translateX(0);
          }
        }
      }
    }

    &.active {
      z-index: 2;
    }
  }

  &--picto,
  &--grid {
    align-items: flex-start;
    display: grid;
    grid-auto-flow: dense;
    justify-items: center;
  }

  &--picto {
    grid-gap: 1.5rem;
    grid-template-columns: repeat(auto-fit, minmax(7rem, 1fr));

    .image {
      height: 4rem;
      max-width: 7rem;

      &__link {
        display: flex;
        justify-content: center;
      }
    }

    .mosaic {
      &__element {
        width: 100%;
      }
    }
  }

  &--grid,
  &--masonry {
    .mosaic {
      &__slide {
        background: rgba(0, 20, 50, .85);
        bottom: 0;
        color: $white;
        left: 0;
        opacity: 0;
        padding: $medium-spacing;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateY(-100%);
        transition: transform .25s, opacity .25s;
      }
    }

    > .mosaic {
      &__element {
        max-width: 100%;
        position: relative;

        &--1x2 {
          grid-row: span 2;
        }

        &--1x3 {
          grid-row: span 3;
        }

        &--2x1 {
          grid-column: span 2;
        }

        &--2x2 {
          grid-column: span 2;
          grid-row: span 2;
        }

        &--3x1 {
          grid-column: span 3;
        }

        &--3x2 {
          grid-column: span 3;
          grid-row: span 2;
        }

        &:nth-of-type(5n + 1) {
          .image {
            &__wrapper {
              transform-origin: top left;
            }
          }
        }

        &:nth-of-type(5n + 2) {
          .image {
            &__wrapper {
              transform-origin: top right;
            }
          }
        }

        &:nth-of-type(5n + 3) {
          .image {
            &__wrapper {
              transform-origin: bottom left;
            }
          }
        }

        &:nth-of-type(5n + 4) {
          .image {
            &__wrapper {
              transform-origin: bottom right;
            }
          }
        }

        &:nth-of-type(5n + 5) {
          .image {
            &__wrapper {
              transform-origin: center;
            }
          }
        }
      }
    }
  }

  &--grid {
    grid-gap: 0 $small-spacing;
    grid-template-columns: repeat(auto-fit, minmax(calc(100% / 3 - 2rem), 1fr));
  }

  &--masonry {
    // clear fix
    &::after {
      clear: both;
      content: '';
      display: block;
    }

    > .mosaic {
      &__element {
        float: left;
        margin: $tiny-spacing;
        overflow: hidden;
        width: calc(100% / 3 - .5rem);

        &:nth-of-type(3n+1) {
          .mission {
            margin-left: -20vw;
          }
        }

        &:nth-of-type(3n+2) {
          .mission {
            margin-left: calc(-20vw - 100% - 1rem);
          }
        }

        &:nth-of-type(3n+3) {
          .mission {
            margin-left: calc(-20vw - 200% - 2rem);
          }
        }
      }
    }

    .mosaic {
      &__element-link {
        &:hover {
          .mosaic {
            &__slide {
              opacity: 1;
              transform: translateX(0);
            }
          }
        }
      }
    }
  }

  &--missions {
    > .mosaic {
      &__element {
        $mosaicMissionElementHeight: 17vw;

        .image {
          &__wrapper {
            height: $mosaicMissionElementHeight;
          }
        }
      }
    }
  }

  &--mission-thumbnails {
    grid-gap: $small-spacing;
    grid-template-columns: repeat(auto-fit, minmax(calc(100% / 3 - 1rem), 1fr));
    margin-top: $default-spacing;

    .image {
      &__wrapper {
        height: 5.5vw;
      }
    }
  }
}

@import 'media-queries';
